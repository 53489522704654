@font-face {
  font-family: "Flat-UI-Icons";
  src: url("../fonts/Startup-Icons.eot");
  src: url("../fonts/Startup-Icons.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Startup-Icons.woff") format("woff"),
       url("../fonts/Startup-Icons.ttf") format("truetype"),
       url("../fonts/Startup-Icons.svg#Flat-UI-Icons") format("svg");
  font-weight: normal;
  font-style: normal;
}


/* Use the following CSS code if you want to use data attributes for inserting your icons */
[data-icon]:before {
  font-family: 'Flat-UI-Icons';
  content: attr(data-icon);
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="fui-"],
[class*="fui-"] {
  font-family: 'Flat-UI-Icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fui-triangle-up:before {
  content: "\e600";
}
.fui-triangle-down:before {
  content: "\e601";
}
.fui-triangle-up-small:before {
  content: "\e602";
}
.fui-triangle-down-small:before {
  content: "\e603";
}
.fui-triangle-left-large:before {
  content: "\e604";
}
.fui-triangle-right-large:before {
  content: "\e605";
}
.fui-arrow-left:before {
  content: "\e606";
}
.fui-arrow-right:before {
  content: "\e607";
}
.fui-plus:before {
  content: "\e608";
}
.fui-cross:before {
  content: "\e609";
}
.fui-check:before {
  content: "\e60a";
}
.fui-radio-unchecked:before {
  content: "\e60b";
}
.fui-radio-checked:before {
  content: "\e60c";
}
.fui-checkbox-unchecked:before {
  content: "\e60d";
}
.fui-checkbox-checked:before {
  content: "\e60e";
}
.fui-info-circle:before {
  content: "\e60f";
}
.fui-alert-circle:before {
  content: "\e610";
}
.fui-question-circle:before {
  content: "\e611";
}
.fui-check-circle:before {
  content: "\e612";
}
.fui-cross-circle:before {
  content: "\e613";
}
.fui-plus-circle:before {
  content: "\e614";
}
.fui-pause:before {
  content: "\e615";
}
.fui-play:before {
  content: "\e616";
}
.fui-volume:before {
  content: "\e617";
}
.fui-mute:before {
  content: "\e618";
}
.fui-resize:before {
  content: "\e619";
}
.fui-list:before {
  content: "\e61a";
}
.fui-list-thumbnailed:before {
  content: "\e61b";
}
.fui-list-small-thumbnails:before {
  content: "\e61c";
}
.fui-list-large-thumbnails:before {
  content: "\e61d";
}
.fui-list-numbered:before {
  content: "\e61e";
}
.fui-list-columned:before {
  content: "\e61f";
}
.fui-list-bulleted:before {
  content: "\e620";
}
.fui-window:before {
  content: "\e621";
}
.fui-windows:before {
  content: "\e622";
}
.fui-loop:before {
  content: "\e623";
}
.fui-cmd:before {
  content: "\e624";
}
.fui-mic:before {
  content: "\e625";
}
.fui-heart:before {
  content: "\e626";
}
.fui-location:before {
  content: "\e627";
}
.fui-new:before {
  content: "\e628";
}
.fui-video:before {
  content: "\e629";
}
.fui-photo:before {
  content: "\e62a";
}
.fui-time:before {
  content: "\e62b";
}
.fui-eye:before {
  content: "\e62c";
}
.fui-chat:before {
  content: "\e62d";
}
.fui-home:before {
  content: "\e62e";
}
.fui-upload:before {
  content: "\e62f";
}
.fui-search:before {
  content: "\e630";
}
.fui-user:before {
  content: "\e631";
}
.fui-mail:before {
  content: "\e632";
}
.fui-lock:before {
  content: "\e633";
}
.fui-power:before {
  content: "\e634";
}
.fui-calendar:before {
  content: "\e635";
}
.fui-gear:before {
  content: "\e636";
}
.fui-bookmark:before {
  content: "\e637";
}
.fui-exit:before {
  content: "\e638";
}
.fui-trash:before {
  content: "\e639";
}
.fui-folder:before {
  content: "\e63a";
}
.fui-bubble:before {
  content: "\e63b";
}
.fui-export:before {
  content: "\e63c";
}
.fui-calendar-solid:before {
  content: "\e63d";
}
.fui-star:before {
  content: "\e63e";
}
.fui-star-2:before {
  content: "\e63f";
}
.fui-credit-card:before {
  content: "\e640";
}
.fui-clip:before {
  content: "\e641";
}
.fui-link:before {
  content: "\e642";
}
.fui-tag:before {
  content: "\e643";
}
.fui-document:before {
  content: "\e644";
}
.fui-image:before {
  content: "\e645";
}
.fui-facebook:before {
  content: "\e646";
}
.fui-youtube:before {
  content: "\e647";
}
.fui-vimeo:before {
  content: "\e648";
}
.fui-twitter:before {
  content: "\e649";
}
.fui-spotify:before {
  content: "\e64a";
}
.fui-skype:before {
  content: "\e64b";
}
.fui-pinterest:before {
  content: "\e64c";
}
.fui-path:before {
  content: "\e64d";
}
.fui-linkedin:before {
  content: "\e64e";
}
.fui-google-plus:before {
  content: "\e64f";
}
.fui-dribbble:before {
  content: "\e650";
}
.fui-behance:before {
  content: "\e651";
}
.fui-stumbleupon:before {
  content: "\e652";
}
.fui-yelp:before {
  content: "\e653";
}
.fui-wordpress:before {
  content: "\e654";
}
.fui-windows-8:before {
  content: "\e655";
}
.fui-vine:before {
  content: "\e656";
}
.fui-tumblr:before {
  content: "\e657";
}
.fui-paypal:before {
  content: "\e658";
}
.fui-lastfm:before {
  content: "\e659";
}
.fui-instagram:before {
  content: "\e65a";
}
.fui-html5:before {
  content: "\e65b";
}
.fui-github:before {
  content: "\e65c";
}
.fui-foursquare:before {
  content: "\e65d";
}
.fui-dropbox:before {
  content: "\e65e";
}
.fui-android:before {
  content: "\e65f";
}
.fui-apple:before {
  content: "\e660";
}
