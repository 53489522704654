.dropdown-arrow-inverse {
  border-bottom-color: #34495e !important;
  border-top-color: #34495e !important;
}
/* styles from flat-ui-free2.0.0-fix.less files */
body {
  color: #34495e;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.231;
}
input,
button,
select,
textarea {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
}
a {
  color: #16a085;
  text-decoration: none;
  -webkit-transition: color 0.25s, background 0.25s;
  -moz-transition: color 0.25s, background 0.25s;
  -o-transition: color 0.25s, background 0.25s;
  transition: color 0.25s, background 0.25s;
}
a:hover {
  color: #1abc9c;
  text-decoration: none;
}
h1,
.h1 {
  font-size: 48px /* 64px */;
}
h2,
.h2 {
  font-size: 41px /* 52px */;
}
h3,
.h3 {
  font-size: 32px /* 40px */;
}
h4,
.h4 {
  font-size: 23px /* 29px */;
}
h5,
.h5 {
  font-size: 21px /* 28px */;
}
h6,
.h6 {
  font-size: 19px /* 24px */;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  line-height: 1.2em;
}
h1 {
  font-weight: 700;
}
h2 {
  font-weight: 700;
  margin-bottom: 2px;
}
h3 {
  font-weight: 700;
  margin-bottom: 4px;
  margin-top: 2px;
}
h4 {
  font-weight: 500;
  margin-top: 4px;
}
h5 {
  font-weight: 500;
}
h6 {
  font-weight: normal;
}
p {
  font-size: 18.004px;
  /* 18px */
  line-height: 1.722169;
  /* 31px */
}
.lead {
  font-size: 28px;
  /* 28px */
  line-height: 1.4642745;
  /* 41px */
  font-weight: 300;
  margin-bottom: 20px;
}
small {
  font-size: 84%;
  /* 15px */
  line-height: 2.050846;
  /* 31px */
}
blockquote {
  position: relative;
  border-left: none;
  padding-left: 19px;
}
blockquote p {
  font-size: 20.006px;
  line-height: 1.549829;
  font-weight: normal;
  margin-bottom: .4em;
}
blockquote small {
  font-size: 18.004px;
  line-height: 1.722169;
  font-style: italic;
  color: #34495e;
}
blockquote small:before {
  content: "";
}
blockquote:before {
  position: absolute;
  left: 0;
  width: 3px;
  height: 100%;
  top: 0;
  bottom: 0;
  background-color: #ebedee;
  content: "";
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.img-rounded {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.img-comment {
  font-size: 14.994px;
  line-height: 1.231;
  font-style: italic;
  margin: 24px 0;
}
.text-primary {
  color: #1abc9c;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #15967d;
}
.text-success {
  color: #2ecc71;
}
a.text-success:hover,
a.text-success:focus {
  color: #25a35a;
}
.text-danger {
  color: #e74c3c;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #b93d30;
}
.text-warning {
  color: #f1c40f;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #c19d0c;
}
.text-info {
  color: #3498db;
}
a.text-info:hover,
a.text-info:focus {
  color: #2a7aaf;
}
.text-inverse {
  color: white;
}
a.text-inverse:hover,
a.text-inverse:focus {
  color: #cccccc;
}
.muted {
  color: #bdc3c7;
}
a.muted:hover,
a.muted:focus {
  color: #979c9f;
}
footer {
  background-color: transparent;
}
.input-append input {
  -webkit-border-radius: 6px 0 0 6px;
  -moz-border-radius: 6px 0 0 6px;
  border-radius: 6px 0 0 6px;
}
.bg-white {
  background-color: #ffffff;
}
.bg-black {
  background-color: #000000;
}
.bg-turquoise {
  background-color: #1abc9c;
}
.bg-green-sea {
  background-color: #16a085;
}
.bg-emerland {
  background-color: #2ecc71;
}
.bg-nephritis {
  background-color: #27ae60;
}
.bg-peter-river {
  background-color: #3498db;
}
.bg-belize-hole {
  background-color: #2980b9;
}
.bg-amethyst {
  background-color: #9b59b6;
}
.bg-wisteria {
  background-color: #8e44ad;
}
.bg-wet-asphalt {
  background-color: #34495e;
}
.bg-midnight-blue {
  background-color: #2c3e50;
}
.bg-sun-flower {
  background-color: #f1c40f;
}
.bg-orange {
  background-color: #f39c12;
}
.bg-carrot {
  background-color: #e67e22;
}
.bg-pumpkin {
  background-color: #d35400;
}
.bg-alizarin {
  background-color: #e74c3c;
}
.bg-pomegranate {
  background-color: #c0392b;
}
.bg-clouds {
  background-color: #ecf0f1;
}
.bg-silver {
  background-color: #bdc3c7;
}
.bg-concrete {
  background-color: #95a5a6;
}
.bg-asbestos {
  background-color: #7f8c8d;
}
.bg-base {
  background-color: #34495e;
}
.bg-firm {
  background-color: #1abc9c;
}
.bg-success {
  background-color: #2ecc71;
}
.bg-danger {
  background-color: #e74c3c;
}
.bg-warning {
  background-color: #f1c40f;
}
.bg-info {
  background-color: #3498db;
}
.bordered {
  border-style: solid;
}
.bordered.bg-turquoise {
  border-color: #16a085;
}
.bordered.bg-emerland {
  border-color: #27ae60;
}
.bordered.bg-peter-river {
  border-color: #2980b9;
}
.bordered.bg-amethyst {
  border-color: #8e44ad;
}
.bordered.bg-wet-asphalt {
  border-color: #2c3e50;
}
.bordered.bg-sun-flower {
  border-color: #f39c12;
}
.bordered.bg-carrot {
  border-color: #d35400;
}
.bordered.bg-alizarin {
  border-color: #c0392b;
}
.bordered.bg-clouds {
  border-color: #bdc3c7;
}
.bordered.bg-concrete {
  border-color: #7f8c8d;
}
html,
body {
  height: 100%;
}
body {
  overflow-x: hidden;
  color: #7f8c8d;
  line-height: 1.29;
}
@media (max-width: 767px) {
  body {
    padding-right: 0;
    padding-left: 0;
  }
}
li {
  line-height: 20px;
}
.btn {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  line-height: 22px;
  -webkit-transition: color 0.25s, background 0.25s;
  -moz-transition: color 0.25s, background 0.25s;
  -o-transition: color 0.25s, background 0.25s;
  transition: color 0.25s, background 0.25s;
}
.btn.btn-large {
  font-size: 17px;
  padding: 12px 18px;
}
.btn > [class^="fui-"] {
  margin-left: 4px;
  margin-right: 4px;
  line-height: inherit;
  display: inline-block;
  vertical-align: top;
}
.input-group-btn .btn {
  background: #bdc3c7;
  line-height: 9px 12px 10px;
  line-height: 22px;
  border: 0;
  margin: 0;
  color: #fff;
  margin-left: 0 !important;
  padding-left: 10px;
  padding-right: 12px;
}
.input-group-btn .btn:hover {
  background: #cacfd2;
}
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  outline: none;
}
.form-group {
  margin-bottom: 10px;
}
label {
  line-height: 20px;
}
.carousel {
  line-height: 1;
}
.hero-unit h1 {
  margin-bottom: 0;
  font-size: 60px;
  line-height: 1;
  letter-spacing: -1px;
  color: inherit;
}
h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}
p {
  margin-bottom: 10px;
}
.page-wrapper {
  position: relative;
  z-index: 2;
  background: #fff;
  overflow: hidden;
  min-height: 100%;
}
.header-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.loaded .page-wrapper,
.loaded .header-antiflicker,
.loaded body > header .header-background {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.33s cubic-bezier(0.694, 0.0482, 0.335, 1);
  -moz-transition: all 0.33s cubic-bezier(0.694, 0.0482, 0.335, 1);
  -o-transition: all 0.33s cubic-bezier(0.694, 0.0482, 0.335, 1);
  transition: all 0.33s cubic-bezier(0.694, 0.0482, 0.335, 1);
}
.loaded.nav-visible .page-wrapper,
.loaded.nav-visible .navbar.navbar-fixed-top,
.loaded.nav-visible .header-antiflicker,
.loaded.nav-visible body > header .header-background {
  -webkit-transform: translate3d(-230px, 0, 0);
  -moz-transform: translate3d(-230px, 0, 0);
  -o-transform: translate3d(-230px, 0, 0);
  transform: translate3d(-230px, 0, 0);
}
.loaded.no-csstransitions .page-wrapper,
.loaded.no-csstransitions .header-antiflicker {
  left: 0;
}
.loaded.no-csstransitions.nav-visible .page-wrapper,
.loaded.no-csstransitions.nav-visible .navbar.navbar-fixed-top,
.loaded.no-csstransitions.nav-visible .header-antiflicker {
  left: -230px !important;
  right: auto;
}
.carousel-indicators {
  width: 100%;
}
[class^="fui-"] {
  display: inline-block;
  vertical-align: middle;
}
body > section,
header,
footer {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
@media (max-width: 767px) {
  body > section,
  header,
  footer {
    padding-right: 5px;
    padding-left: 5px;
  }
}
img {
  width: auto\9;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #2c3e50;
}
h1 {
  font-weight: normal;
}
h3 {
  font-weight: normal;
}
img {
  -webkit-backface-visibility: hidden;
}
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  color: #7f8c8d;
}
a:hover,
button:hover,
a:focus,
button:focus,
a:active,
button:active {
  outline: none;
}
#bgVideo {
  overflow: hidden;
}
#bgVideo > video {
  position: absolute !important;
}
.delimiter {
  margin-top: 200px;
}
.delimiter.line {
  margin-top: 150px;
  margin-bottom: 150px;
  border-top: 2px solid #ebedee;
}
header {
  padding-top: 100px;
  padding-bottom: 70px;
  z-index: 100;
}
header .brand {
  font-size: 25px;
  font-weight: normal;
  color: #34495e;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 800;
  letter-spacing: -1px;
  color: #2c3e50;
}
header .brand img:first-child {
  float: left;
  margin: -10px 15px 0 0;
}
section * {
  z-index: 2;
}
.background {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: 50% 50% no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  opacity: 20;
  filter: alpha(opacity=2000);
  opacity: 0.2;
  filter: alpha(opacity=20);
}
.container {
  position: relative;
  z-index: 2;
}
@media (max-width: 767px) {
  header {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}
.navbar {
  left: 0;
  right: 0;
}
.navbar .brand {
  padding: 23px 28px 24px 0;
  float: left;
}
.navbar .subnav {
  display: none;
}
.navbar a {
  -webkit-transition: color 0.25s, background 0.25s;
  -moz-transition: color 0.25s, background 0.25s;
  -o-transition: color 0.25s, background 0.25s;
  transition: color 0.25s, background 0.25s;
}
.navbar .navbar-collapse {
  padding-left: 0;
  padding-right: 0;
}
.navbar .nav > li {
  float: left;
}
.navbar .nav > li > a:hover,
.navbar .nav > li > a:focus {
  background: none;
}
.navbar .navbar-toggle {
  margin: 26px 0 10px;
  color: white;
  background: none;
  padding: 0;
  width: 30px;
  height: 30px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.navbar .navbar-toggle:before {
  display: none;
}
.navbar .navbar-form .btn {
  height: auto;
  line-height: 22px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.navbar .nav > li > a > [class*="fui-"] {
  font-size: 24px;
  margin: -10px 0 0;
  position: relative;
  top: 4px;
}
.navbar.navbar-fixed-top {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  max-width: 1170px;
  margin: 0 auto;
}
.loaded .navbar.navbar-fixed-top {
  -webkit-transition: all 0.33s cubic-bezier(0.694, 0.0482, 0.335, 1);
  -moz-transition: all 0.33s cubic-bezier(0.694, 0.0482, 0.335, 1);
  -o-transition: all 0.33s cubic-bezier(0.694, 0.0482, 0.335, 1);
  transition: all 0.33s cubic-bezier(0.694, 0.0482, 0.335, 1);
}
@media (min-width: 768px) and (max-width: 991px) {
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-header {
    float: none;
  }
}
.header-19 .navbar-collapse.collapse,
.header-16 .navbar-collapse.collapse {
  display: none !important;
}
@media (max-width: 991px) {
  body .navbar-collapse {
    width: 230px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100% !important;
    z-index: 1;
  }
}
@media (min-width: 992px) {
  body > .collapse.navbar-collapse {
    display: none !important;
  }
}
body .navbar-collapse#header-19,
body .navbar-collapse#header-16 {
  width: 230px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100% !important;
  z-index: 1;
}
body > .navbar-collapse {
  padding: 25px 0;
  overflow: auto;
  background: #222527;
  min-height: 100%;
}
body > .navbar-collapse::-webkit-scrollbar {
  display: none;
}
body > .navbar-collapse .pull-right,
body > .navbar-collapse .pull-left {
  float: none !important;
}
body > .navbar-collapse .nav {
  margin: 0 0 10px;
}
body > .navbar-collapse .nav > li {
  margin: 0;
}
body > .navbar-collapse .nav > li > a {
  display: block;
  padding: 12px 35px;
  border-bottom: 2px solid #24292d;
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 1px;
  color: #5b5e61;
  text-transform: uppercase;
}
body > .navbar-collapse .nav > li > a:hover,
body > .navbar-collapse .nav > li > a:focus,
body > .navbar-collapse .nav > li > a.active {
  color: #fff;
}
body > .navbar-collapse .nav > li > a:hover,
body > .navbar-collapse .nav > li > a:focus {
  background: none;
}
body > .navbar-collapse .nav > li.active > a {
  color: #fff;
}
body > .navbar-collapse .nav > li.active > a:hover,
body > .navbar-collapse .nav > li.active > a:focus,
body > .navbar-collapse .nav > li.active > a.active {
  color: #fff;
}
body > .navbar-collapse .subnav {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}
body > .navbar-collapse .subnav li > a {
  display: block;
  margin: 0;
  padding: 7px 35px;
  font-size: 13px;
  font-weight: normal;
  color: #5b5e61;
  text-transform: capitalize;
}
body > .navbar-collapse .subnav li > a:hover,
body > .navbar-collapse .subnav li > a:focus,
body > .navbar-collapse .subnav li > a.active {
  color: #fff;
}
body > .navbar-collapse .navbar-form,
body > .navbar-collapse .navbar-search {
  padding: 10px 35px;
  margin: 0;
  border-bottom: 0 none;
  border-top: 0 none;
  box-shadow: none;
}
body > .navbar-collapse .navbar-form:focus,
body > .navbar-collapse .navbar-search:focus {
  outline: 0 none;
}
body > .navbar-collapse .navbar-form .btn,
body > .navbar-collapse .navbar-search .btn,
body > .navbar-collapse .navbar-form .btn-primary,
body > .navbar-collapse .navbar-search .btn-primary {
  height: 30px;
  line-height: 20px;
  font-size: 15px;
  color: #5b5e61;
  background: none;
  padding: 0;
}
body > .navbar-collapse .navbar-form .btn:hover,
body > .navbar-collapse .navbar-search .btn:hover,
body > .navbar-collapse .navbar-form .btn-primary:hover,
body > .navbar-collapse .navbar-search .btn-primary:hover {
  color: #fff;
}
/**
 * Header 1 stylesheet
 * */
.header-1 {
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
}
.header-1 > .container {
  position: relative;
}
.header-1 .navbar {
  position: absolute;
  z-index: 2;
  top: 45px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
}
.header-1 .navbar.navbar-fixed-top {
  position: fixed;
}
.header-1 .navbar .brand {
  font-size: 25px;
  font-weight: normal;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 800;
  color: white;
}
.header-1 .navbar .brand:hover,
.header-1 .navbar .brand:focus,
.header-1 .navbar .brand.active {
  color: white;
}
.header-1 .navbar .brand img:first-child {
  float: left;
  margin: -10px 15px 0 0;
}
.header-1 .navbar .nav > li {
  margin-left: 20px;
}
.header-1 .navbar .nav > li:first-child {
  margin-left: 0;
}
.header-1 .navbar .nav > li > a {
  padding: 35px 0 27px;
  font-size: 13px;
  font-weight: normal;
  font-weight: 800;
  letter-spacing: 1px;
  color: white;
}

.header-1-sub {
  position: relative;
  z-index: 50;
  left: 0;
  width: 100%;
  height: 500px;
  padding: 0;
  overflow: hidden;
  color: white;
  background-color: #222527;
}
@media (max-width: 480px) {
  .header-1-sub {
    height: 420px;
  }
}
.header-1-sub .container {
  position: relative;
}
@media (max-width: 767px) {
  .header-1-sub .container {
    margin-right: 5px;
    margin-left: 5px;
  }
}
.header-1-sub h3 {
  margin: 0 0 20px 0;
  font-size: 36px;
  font-weight: normal;
  color: white;
  font-weight: 500;
}
@media (max-width: 600px) {
  .header-1-sub h3 {
    font-size: 30px;
    font-weight: normal;
    color: white;
  }
}
@media (max-width: 480px) {
  .header-1-sub h3 {
    font-size: 26px;
    font-weight: normal;
    color: white;
    margin: 0 0 24px 0;
  }
}
.header-1-sub .lead {
  font-size: 30px;
  font-weight: normal;
  color: white;
  font-weight: 300;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 0;
}
@media (max-width: 600px) {
  .header-1-sub .lead {
    font-size: 26px;
    font-weight: normal;
    color: white;
  }
}
@media (max-width: 480px) {
  .header-1-sub .lead {
    font-size: 22px;
    font-weight: normal;
    color: white;
  }
}
.header-1-sub .pt-perspective {
  position: relative;
  height: 100%!important;
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  perspective: 1200px;
}
/*
 Note: these styles have been modified to support just one
 feature image with no bx slider. We don't need it for our site.
*/
.header-1-sub .pt-page {
  width: 100%;
  height: 100%!important;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.header-1-sub .pt-page-1 .background {
  background-image: url("images/tekapo.jpg");
  opacity: 75;
  filter: alpha(opacity=7500);
  opacity: 0.75;
  filter: alpha(opacity=75);
  background-position: 50% 30%;
}
.pt-page-current { background-color: #222527; }
.header-1-sub .pt-page-current,
.header-1-sub .no-js .pt-page {
  visibility: visible;
  z-index: 1;
}
.header-1-sub .pt-page-ontop {
  z-index: 999;
}
.header-1-sub .page-transitions * {
  line-height: normal;
}
.header-1-sub .page-transitions .background {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.header-1-sub .page-transitions .caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 120px;
}
@media (max-width: 480px) {
  .header-1-sub .page-transitions .caption {
    bottom: 73px;
  }
}
.header-1-sub .page-transitions .controls > * {
  position: absolute;
  left: 15;
  top: 410px;
  z-index: 1000;
  width: 20px;
  overflow: hidden;
  text-decoration: none;
  font-size: 18px;
  color: #7f8c8d;
  font-weight: normal;
  font-style: normal;
  color: white;
  -webkit-transition: color 0.25s;
  -moz-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
}
.header-1-sub .page-transitions .controls > *:hover,
.header-1-sub .page-transitions .controls > *:focus,
.header-1-sub .page-transitions .controls > *.active {
  color: rgba(0, 0, 0, 0.4);
}
@media (max-width: 480px) {
  .header-1-sub .page-transitions .controls > * {
    top: 363px;
  }
}
.header-1-sub .page-transitions .controls > *.control-next {
  left: 45px;
}
/**
 * Content 3 stylesheet
 * */
.content-3 {
  padding-top: 130px;
  padding-bottom: 130px;
  position: relative;
}
.content-3 h3 {
  font-size: 30px;
  font-weight: normal;
  color: #2c3e50;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  margin-bottom: 24px;
}
.content-3 p {
  font-size: 16px;
  font-weight: normal;
  color: #7f8c8d;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 30px;
}
@media (max-width: 767px) {
  .content-3 p {
    margin-bottom: 34px;
  }
}
.content-3.v-center,
.content-3 .v-center {
  display: table;
  width: 100%;
}
.content-3.v-center > div,
.content-3 .v-center > div {
  display: table-cell;
  vertical-align: middle;
  margin-top: 0;
  margin-bottom: 0;
  float: none;
}
@media (min-width: 768px) {
  .content-3.v-center.row:before,
  .content-3 .v-center.row:before,
  .content-3.v-center.row:after,
  .content-3 .v-center.row:after {
    display: none;
  }
}
@media (max-width: 767px) {
  .content-3.v-center,
  .content-3 .v-center {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 5px;
    padding-right: 5px;
  }
  .content-3.v-center.row,
  .content-3 .v-center.row {
    display: block;
    width: auto;
  }
  .content-3.v-center.row:before,
  .content-3 .v-center.row:before,
  .content-3.v-center.row:after,
  .content-3 .v-center.row:after {
    display: none;
  }
  .content-3.v-center.row > *,
  .content-3 .v-center.row > * {
    display: block;
    vertical-align: baseline;
  }
}
.content-3 .img {
  max-width: 380px;
  margin-left: auto;
}
.content-3 .img img {
  width: 100%;
}
@media (max-width: 767px) {
  .content-3 .img {
    margin-right: auto;
    margin-bottom: 30px;
  }
}
.content-3 .delimiter {
  margin-top: 130px;
  padding-top: 70px;
  border-top: 2px solid #ecf0f1;
}
@media (max-width: 767px) {
  .content-3 .delimiter {
    display: none;
  }
}
.content-3 .features h6 {
  margin-top: 0;
  margin-bottom: 22px;
  font-size: 20px;
  font-weight: normal;
  color: #34495e;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  text-transform: none;
}
@media (max-width: 767px) {
  .content-3 .features h6 {
    margin-top: 30px;
    margin-bottom: 15px;
  }
}
.content-3 .features p {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 26px;
}
.content-3.mirror .aligment {
  float: right;
}
.content-3.mirror .img {
  margin-left: 0;
  margin-right: auto;
  max-width: 397px;
}
@media (max-width: 767px) {
  .content-3.mirror .img {
    margin-left: auto;
  }
}

/**
 * Content 5 stylesheet
 * */
.content-5 {
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
}

.content-5 .container > img {
  display: block;
  margin: 0 auto 50px;
}
.content-5 h3 {
  margin: 0 0 21px;
  font-size: 35px;
  font-weight: normal;
  color: #2c3e50;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
}
@media (max-width: 767px) {
  .content-5 h3 {
    margin-bottom: 15px;
  }
}
.content-5 h3 + .lead {
  font-size: 25px;
  font-weight: normal;
  color: #7f8c8d;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  text-align: center;
}
@media (max-width: 767px) {
  .content-5 h3 + .lead {
    line-height: 1.25;
  }
}
@media (max-width: 480px) {
  .content-5 {
    padding-bottom: 0px;
  }
}
.content-5 .features > [class*="col-sm-"] {
  position: relative;
  margin-top: 40px;
}
.content-5 .features > [class*="col-sm-"] > [class*="fui-"] {
  position: absolute;
  left: -45px;
  top: 7px;
  font-size: 25px;
  font-weight: normal;
  color: #2c3e50;
}
@media (max-width: 767px) {
  .content-5 .features > [class*="col-sm-"] > [class*="fui-"] {
    position: static;
    float: left;
    margin: -7px 20px 0 0;
  }
}
.content-5 .features > [class*="col-sm-"] p {
  font-size: 16px;
  font-weight: normal;
  color: #7f8c8d;
}
.content-5 .features > [class*="col-sm-"] h6 {
  font-size: 22px;
  font-weight: normal;
  color: #34495e;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  margin-bottom: 14px;
  text-transform: none;
}


.projects-* .pt-perspective {
  position: relative;
  height: 100%;
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  perspective: 1200px;
}
.projects-* .pt-page {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.projects-* .pt-page-current,
.projects-* .no-js .pt-page {
  visibility: visible;
  z-index: 1;
}
.projects-* .pt-page-ontop {
  z-index: 999;
}
.projects-* .v-center {
  display: table;
  width: 100%;
}
.projects-* .v-center > * {
  display: table-cell;
  vertical-align: middle;
  margin: 0;
  float: none;
}
@media (max-width: 767px) {
  .projects-* .v-center.row {
    display: block;
    width: auto;
  }
  .projects-* .v-center.row > * {
    display: block;
    vertical-align: baseline;
  }
}
/**
 * Content 4 stylesheet
 * */
.content-4 {
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;
}
.content-4 h3 {
  font-size: 30px;
  font-weight: normal;
  color: #2c3e50;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 24px;
  font-weight: 300;
}
.content-4 p {
  font-size: 16px;
  font-weight: normal;
  color: #7f8c8d;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 26px;
}

.content-4 .features {
  margin-top: 60px;
}

.content-4 .features h6 {
  margin: 0 0 13px;
  font-size: 24px;
  font-weight: normal;
  color: #34495e;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  text-transform: none;
  text-align: center;
}
.content-4 .features p {
  font-size: 16px;
  font-weight: normal;
  color: #7f8c8d;
  text-align: center;
}
.content-4 .features > [class*="col-sm-"] {
  position: relative;
  /*padding-top: 180px;*/
  padding-bottom: 50px;
}
.content-4 .features > [class*="col-sm-"] .center-image {
  text-align: center;
  height: 130px;
}
.content-4 .features > [class*="col-sm-"] > img:first-child {
  /*position: absolute;
  left: 15px;
  top: 30px;*/
  display: inline-block;
}

.content-4 .features:last-child > [class*="col-sm-"],
.content-4 .features.last > [class*="col-sm-"] {
  padding-bottom: 0;
}
@media (max-width: 767px) {
  .content-4 .features > [class*="col-sm-"],
  .content-4 .features:last-child > [class*="col-sm-"],
  .content-4 .features.last > [class*="col-sm-"] {
    padding-top: 50px;
    padding-bottom: 20px;
  }
  .content-4 .features:last-child > [class*="col-sm-"]:last-child,
  .content-4 .features.last > [class*="col-sm-"]:last-child,
  .content-4 .features:last-child > [class*="col-sm-"].last,
  .content-4 .features.last > [class*="col-sm-"].last {
    padding-bottom: 0;
  }

  .content-4 .features {
    margin-top: 0;
  }

  .content-4 h3 {
    text-align: center;
  }
}
/**
 * Project 4 stylesheet
 * */
.projects-4 {
  padding-top: 110px;
  padding-bottom: 110px;
}
.projects-4 h3 {
  margin: 0 0 60px;
  font-size: 30px;
  font-weight: 300;
  text-align: center;
}
.projects-4 .projects + .projects {
  margin-top: 80px;
}
.projects-4 .projects .photo-wrapper {
  height: 145px;
  position: relative;
  overflow: hidden;
}
.projects-4 .projects .photo {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  background: 50% 50% no-repeat;
  opacity: 30;
  filter: alpha(opacity=3000);
  opacity: 0.3;
  filter: alpha(opacity=30);
  -webkit-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  transition: opacity 0.4s;
}
.projects-4 .projects .overlay {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  text-align: center;
  color: #fff;
  line-height: 145px;
}
.projects-4 .projects .overlay [class*="fui-"] {
  font-size: 46px;
}
.projects-4 .projects .info {
  text-align: left;
  font-size: 13px;
  color: #95a5a6;
  margin-top: 27px;
}
.projects-4 .projects .info b {
  color: #7f8c8d;
}
.projects-4 .projects .name {
  color: #34495e;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
  display: block;
}
.projects-4 .projects .name.color-pomegranate:hover,
.projects-4 .projects .name.color-pomegranate.hover {
  color: #c0392b;
  -webkit-transition: color 0.4s;
  -moz-transition: color 0.4s;
  -o-transition: color 0.4s;
  transition: color 0.4s;
}
.projects-4 .projects .name.color-orange:hover,
.projects-4 .projects .name.color-orange.hover {
  color: #f39c12;
  -webkit-transition: color 0.4s;
  -moz-transition: color 0.4s;
  -o-transition: color 0.4s;
  transition: color 0.4s;
}
.projects-4 .projects .name.color-belize-hole:hover,
.projects-4 .projects .name.color-belize-hole.hover {
  color: #2980b9;
  -webkit-transition: color 0.4s;
  -moz-transition: color 0.4s;
  -o-transition: color 0.4s;
  transition: color 0.4s;
}
.projects-4 .projects .name.color-green-sea:hover,
.projects-4 .projects .name.color-green-sea.hover {
  color: #16a085;
  -webkit-transition: color 0.4s;
  -moz-transition: color 0.4s;
  -o-transition: color 0.4s;
  transition: color 0.4s;
}
.projects-4 .projects .project:hover .photo {
  opacity: 40;
  filter: alpha(opacity=4000);
  opacity: 0.4;
  filter: alpha(opacity=40);
}
@media (max-width: 767px) {
  .projects-4 .projects .project-wrapper + .project-wrapper {
    margin-top: 80px;
  }
}
/**
 * Crew 4 stylesheet
 * */
.crew-4 {
  padding-top: 120px;
  padding-bottom: 0px;
}
.crew-4 h3 {
  margin: 0 0 73px;
  font-size: 35px;
  text-align: center;
  font-weight: normal;
}
.crew-4 .members {
  display: table;
  width: 100%;
}
.crew-4 .members + .members {
  margin-top: 80px;
}
.crew-4 .members .member-wrapper {
  display: table-cell;
}
.crew-4 .members .member-wrapper:first-child + .member-wrapper {
  text-align: center;
}
.crew-4 .members .member-wrapper:first-child + .member-wrapper + .member-wrapper {
  text-align: right;
}
.crew-4 .members .member {
  width: 265px;
  display: inline-block;
}
.crew-4 .members .photo-wrapper {
  width: 265px;
  height: 265px;
  position: relative;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  overflow: hidden;
  background-color: #ecf0f1;
  -webkit-transition: background-color 0.4s;
  -moz-transition: background-color 0.4s;
  -o-transition: background-color 0.4s;
  transition: background-color 0.4s;
}
.crew-4 .members .photo {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  overflow: hidden;
  -webkit-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  transition: opacity 0.4s;
}

.crew-4 .members .overlay {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  text-align: center;
  color: #fff;
  line-height: 180px;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  transition: opacity 0.4s;
}
.crew-4 .members .overlay a {
  margin: 45px 8px;
  display: inline-block;
  color: #fff;
}
.crew-4 .members .overlay a:hover,
.crew-4 .members .overlay a:focus,
.crew-4 .members .overlay a.active {
  color: #e3e7e8;
}
.crew-4 .members .overlay [class*="fui-"] {
  font-size: 36px;
}
.crew-4 .members .info {
  font-size: 14px;
  color: #95a5a6;
  margin-top: 36px;
  line-height: 1.4;
  text-align: left;
}
.crew-4 .members .info b {
  color: #7f8c8d;
}
.crew-4 .members .name {
  color: #1abc9c;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 14px;
}
.crew-4 .members .contacts {
  color: #7f8c8d;
  font-weight: 700;
  border-top: 2px solid #ebedee;
  margin-top: 13px;
  padding-top: 15px;
}
.crew-4 .members .contacts a {
  color: #7f8c8d;
}
.crew-4 .members .contacts a:hover,
.crew-4 .members .contacts a:focus,
.crew-4 .members .contacts a.active {
  color: #95a5a6;
}
.crew-4 .members .member:hover .photo-wrapper {
  background-color: #1abc9c;
}
.crew-4 .members .member:hover .photo {
  opacity: 40;
  filter: alpha(opacity=4000);
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.crew-4 .members .member:hover .overlay {
  opacity: 100;
  filter: alpha(opacity=10000);
  opacity: 1;
  filter: alpha(opacity=100);
}
@media (max-width: 991px) and (min-width: 768px) {
  .crew-4 .members .member,
  .crew-4 .members .photo-wrapper {
    width: 230px;
  }
}
@media (max-width: 767px) {
  .crew-4 .members {
    display: block;
    width: auto;
  }
  .crew-4 .members .member-wrapper {
    display: block;
    text-align: center!important;
  }
  .crew-4 .members .member-wrapper + .member-wrapper {
    margin-top: 80px;
  }
}
/**
 * Copntact 2 stylesheet
 * */
.contacts-2 {
  padding-top: 60px;
  padding-bottom: 60px;
}
.contacts-2 h3 {
  font-size: 30px;
  margin: 0 0 40px;
  font-weight: 300px;
}
@media (max-width: 480px) {
  .contacts-2 h3 {
    font-size: 26px;
    margin: 0 0 30px;
  }
}
.contacts-2 h6,
.contacts-2 .h6 {
  margin: 0 0 15px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-transform: none;
}
.contacts-2 label.h6 {
  margin-bottom: 12px;
}
.contacts-2 p {
  font-size: 16px;
  line-height: 1.6;
}
.contacts-2 a {
  color: #1abc9c;
}
.contacts-2 a:hover,
.contacts-2 a:focus,
.contacts-2 a.active {
  color: #15967d;
}
.contacts-2 form {
  margin: 0;
  padding: 46px;
  border: 2px solid #ebedee;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.contacts-2 form input,
.contacts-2 form textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  margin-bottom: 29px;
  border-color: #ebedee;
  color: #7f8c8d;
}
.contacts-2 form input:focus,
.contacts-2 form textarea:focus {
  border-color: #1abc9c;
}
.contacts-2 form textarea {
  height: 120px;
}
.contacts-2 form input {
  height: 45px;
}
.contacts-2 form textarea {
  height: 120px;
}
.contacts-2 form label {
  margin-bottom: 18px;
}
.contacts-2 form .btn {
  margin-top: 7px;
  min-width: 150px;
}
.contacts-2 form .btn [class*="fui-"] {
  font-size: 24px;
}

.contacts-2 .map {
  height: 165px;
  margin: 25px 0 60px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  overflow: hidden;
}
.contacts-2 .links {
  margin-top: 20px;
}
.contacts-2 .links a {
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  margin-top: 10px;
}
.contacts-2 .links [class*="fui-"] {
  font-size: 26px;
  vertical-align: middle;
  margin: -3px 23px 0 0;
}
@media (max-width: 767px) {
  .contacts-2 > .container > .row > * + * {
    margin-top: 55px;
  }

  .contacts-2 {
    padding-bottom: 0;
  }
}
@media (max-width: 480px) {
  .contacts-2 form {
    margin-bottom: 80px;
    padding: 15px;
  }
  .contacts-2 form label {
    margin-bottom: 0px;
  }
}

.quote-section {
  position: relative;
  margin: 0 0 60px 0;
  padding: 80px 0 90px;
  color: #2c3e50;
  background: #f5f6f6;
  font-size: 30px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
  letter-spacing: -1px;
}
.quote-section:before {
  content: url('images/quote-down-mb@2x.png');
  display: block;
  margin: 0 auto 35px;
  text-align: center;
}
.quote-section .author {
  margin-top: 35px;
  font-size: 18px;
  font-weight: 500;
  color: #2980b9;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
}
@media (max-width: 767px) {
  .quote-section {
    margin-right: -20px;
    margin-left: -20px;
    padding-right: 20px;
    padding-left: 20px;
    margin-bottom: 60px;
  }
}

/**
 * Footer 14 stylesheet
 * */
.footer-14 {
  padding-top: 30px;
  padding-bottom: 30px;
  background: #f4f5f6;
  line-height: 25px;
}
.footer-14 .brand {
  position: absolute;
  top: 0;
  left: 15px;
}
.footer-14 a {
  color: #95a5a6;
  font-weight: 500;
}
.footer-14 a:hover,
.footer-14 a:focus,
.footer-14 a.active {
  color: rgba(0, 0, 0, 0.7);
}
.footer-14 nav {
  font-size: 16px;
  font-weight: normal;
  color: inherit;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-align: center;
  margin: 0 100px;
}
.footer-14 nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.footer-14 nav ul li {
  float: none;
  display: inline-block;
  margin-left: 20px;
  line-height: 25px;
  font-size: 13px;
}
@media (max-width: 767px) {
  .footer-14 nav ul li {
    margin-left: 20px;
  }
}
.footer-14 nav ul li:first-child {
  margin-left: 0;
}
.footer-14 .social-btns {
  position: absolute;
  top: 0;
  right: 15px;
  white-space: nowrap;
}
.footer-14 .social-btns > * {
  display: inline-block;
  margin-left: 15px;
  font-size: 16px;
  font-weight: normal;
  color: #95a5a6;
  width: 16px;
  height: 25px;
  overflow: hidden;
  text-align: center;
  vertical-align: top;
  opacity: 100;
  filter: alpha(opacity=10000);
  opacity: 1;
  filter: alpha(opacity=100);
}
.footer-14 .social-btns > *:first-child {
  margin-left: 0;
}
.footer-14 .social-btns > * > * {
  display: block;
  position: relative;
  top: 0;
  -webkit-transition: 0.25s top;
  -moz-transition: 0.25s top;
  -o-transition: 0.25s top;
  transition: 0.25s top;
}
.footer-14 .social-btns > *:hover > * {
  top: -100%;
}
@media (max-width: 480px) {
  .footer-14 .brand {
    display: none;
  }
  .footer-14 nav {
    text-align: left;
    margin: 0;
  }
  .footer-14 nav ul li {
    float: none;
    margin: 18px 0 0;
    display: block;
  }
  .footer-14 nav ul li:first-child {
    margin-top: 0;
  }
  .footer-14 .social-btns {
    margin: 0 0 30px;
    position: relative;
    display: block;
    float: none;
    right: 0;
  }
}

/**
 * Blog 4 stylesheet
 * */
.blog-4 {
  padding-top: 130px;
  padding-bottom: 130px;
}
.blog-4 a {
  text-decoration: underline;
}
.blog-4 p {
  margin: 0;
}
.blog-4 p + p {
  margin-top: 30px;
}
.blog-4 h3 {
  font-size: 35px;
  margin: 0 0 10px;
}
.blog-4 h6 {
  font-size: 20px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  margin: 0 0 25px;
  text-transform: none;
}
.blog-4 .img {
  max-height: 242px;
  max-width: 538px;
  margin: 70px 0;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  overflow: hidden;
  display: inline-block;
}
@media (max-width: 560px) {
  .blog-4 .img {
    display: block;
  }
}

.blog-4 img {
  display: block;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  max-height: 100%;
}
.blog-4 .quote {
  position: relative;
  margin: 60px 0 55px 40px;
  font-size: 37px;
  line-height: 50px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #2c3e50;
  font-weight: 300;
  letter-spacing: -1px;
}
.blog-4 .quote:before {
  content: url('../../startup/common-files/icons/quote-up.png');
  position: absolute;
  top: -17px;
  left: -40px;
}
@media (max-width: 767px) {
  .blog-4 .quote {
    font-size: 29px;
    line-height: 38px;
  }
}
.blog-4 .tags {
  margin-top: 70px;
  font-size: 12px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.19em;
  color: #bdc3c7;
}
.blog-4 .tags a {
  color: #bdc3c7;
}
.blog-4 .tags a:hover,
.blog-4 .tags a:focus,
.blog-4 .tags a.active {
  color: #979c9f;
}
.blog-4 .tags ul,
.blog-4 .tags ol {
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;
}
.blog-4 .tags li {
  display: inline-block;
  margin-right: 20px;
}
@media (max-width: 767px) {
  .blog-4 .tags {
    display: none;
  }
}
.blog-4 .related-articles {
  margin-top: 82px;
  font-size: 15px;
  color: #bdc3c7;
}
.blog-4 .related-articles h6 {
  font-size: 19px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  margin: 0 0 30px;
}
.blog-4 .related-articles ul,
.blog-4 .related-articles ol {
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;
}
.blog-4 .related-articles li {
  display: block;
  line-height: 1.45;
}
.blog-4 .related-articles li + li {
  margin-top: 30px;
}
.blog-4 .related-articles a {
  color: #1abc9c;
  font-size: 17px;
  font-weight: 500;
}
.blog-4 .related-articles a:hover,
.blog-4 .related-articles a:focus,
.blog-4 .related-articles a.active {
  color: #15967d;
}
.blog-4 .related-articles a + div {
  margin-top: 4px;
}
@media (max-width: 767px) {
  .blog-4 .related-articles {
    margin-top: 60px;
  }
}

/**
 * Header 10 stylesheet
 * */
.header-10 {
  z-index: 100;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  height: 80px;
  color: white;
}
.header-10 .header-background {
  background: #1b1f23;
}
.header-10 .navbar {
  position: absolute;
  z-index: 2;
  top: 0;
  margin: 0;
}
.header-10 .navbar.navbar-fixed-top {
  top: 0 !important;
}
.header-10 .navbar .brand {
  padding-top: 15px;
  font-size: 15px;
  font-weight: normal;
  color: white;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 800;
}
.header-10 .navbar .brand img:first-child {
  float: left;
  margin: -10px 15px 0 0;
}
.header-10 .navbar .nav > li {
  margin-left: 20px;
}
.header-10 .navbar .nav > li:first-child {
  margin-left: 0;
}
.header-10 .navbar .nav > li > a {
  padding: 35px 0 27px;
  font-size: 13px;
  font-weight: normal;
  font-weight: 800;
  letter-spacing: 1px;
  color: white;
}
.header-10 .navbar .nav > li > a:hover,
.header-10 .navbar .nav > li > a:focus,
.header-10 .navbar .nav > li > a.active {
  color: #bdc3c7;
}
.header-10 .navbar .nav > li.active > a {
  color: #bdc3c7;
}
.header-10 .navbar .nav > li.active > a:hover,
.header-10 .navbar .nav > li.active > a:focus,
.header-10 .navbar .nav > li.active > a.active {
  color: #bdc3c7;
}
.header-10 .navbar .navbar-form {
  padding: 19px 0 19px 50px;
}
.header-10 .navbar .btn {
  padding-left: 26px;
  padding-right: 26px;
}
.header-10 .navbar .btn.btn-success {
  font-size: 15px;
  font-weight: normal;
  color: white;
  font-weight: 500;
}
.header-10 .navbar .navbar-toggle {
  margin-top: 30px;
}

/* Form Error Styles */
.form-control.invalid {
  border-color: #e74c3c !important;
}

.invalid-message, .valid-message {
  margin-top: 20px;
  padding: 10px 15px;
  border-radius: 4px;
  font-weight: bold;
  color: white;
}

.invalid-message a {
  color: white !important;
  text-decoration: underline;
  cursor: pointer;
}

.valid-message {
  background-color: #1abc9c;
}

.invalid-message {
  background-color: #e74c3c;
}

/** Price 1 Stylesheet **/
.content-25 {
  position: relative;
  padding: 95px 0;
}
@media (max-width: 767px) {
  .content-25 {
    padding: 40px 5px;
  }
  .content-25 .svg,
  .content-25 .nosvg {
    display: block;
    text-align: center;
    float: none !important;
    margin-top: 45px;
  }
}
.content-25 h3 {
  font-size: 30px;
  font-weight: normal;
  color: #2c3e50;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
}
.content-25 p {
  font-size: 18px;
  color: #7f8c8d;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-top: 45px;
  font-weight: normal;
}
.content-25 svg,
.content-25 .svg {
  width: 100%;
}
.content-25 .nosvg {
  display: none;
}

/**
 * Price 1 stylesheet
 * */
.price-1 {
  padding-top: 110px;
  padding-bottom: 110px;
}
.price-1 .plan {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid #ebedee;
  padding: 25px 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  text-align: center;
  color: #bdc3c7;
  font-size: 18px;
  font-weight: normal;
}
.price-1 .plan > * {
  padding-left: 10px;
  padding-right: 10px;
}
.price-1 .plan b {
  color: #7f8c8d;
}
.price-1 .plan .title {
  color: #2c3e50;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 8px;
}
.price-1 .plan .description {
  margin: 26px 0;
  padding-top: 22px;
  border-top: 2px solid #ebedee;
  line-height: 28px;
  font-weight: normal;
}
.price-1 .plan .description b {
  font-weight: 500;
}
.price-1 .plan .btn {
  min-width: 170px;
}
.price-1 .ribbon {
  position: absolute;
  right: -3px;
  top: 26px;
}
@media (max-width: 991px) {
  .price-1 .ribbon {
    top: 4px;
  }
}
@media (max-width: 767px) {
  .price-1 .ribbon {
    top: 26px;
  }
}
@media (max-width: 767px) {
  .price-1 .plan {
    margin-top: 30px;
  }
  .price-1 .col-sm-4:first-child .plan {
    margin-top: 0;
  }
}

/** Content 25 Stylesheet **/
.content-26 {
  position: relative;
  padding-top: 125px;
  padding-bottom: 125px;
  text-align: center;
}
.content-26 .lead {
  font-size: 22px;
  font-weight: normal;
  color: #7f8c8d;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  margin-bottom: 0;
}
.content-26 h3 {
  font-size: 35px;
  font-weight: normal;
  color: #2c3e50;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  margin: 0 0 36px;
}
@media (max-width: 767px) {
  .content-26 .features {
    margin-top: -52px;
  }
}
.content-26 .features h6 {
  text-transform: none;
  margin-top: 37px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
}
.content-26 .features > * {
  margin-top: 67px;
}
.ribbon {
  display: block;
  position: relative;
  margin: 0;
  padding: 0 10px!important;
  background: #e74c3c;
  color: #fff;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
}
.ribbon:before,
.ribbon:after {
  display: block;
  position: absolute;
  line-height: 0;
}
.ribbon:before {
  content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAgCAYAAADe+2JdAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAIcbAACL/wAA/RMAAIWJAACNJgAA5z0AADj/AAAeyY4zee8AAACLSURBVHja7M6xCcIAEAXQZwwpJI0W6V3AVgdwKrF3CWdwAhFcIjhDKiGCoDGxSVAxyQT5zcG94+5G+X4GS+zqOlEnxAKn72aTANs2aHCtIwGmfWjAAfsxhPIWeaSJVxaris98WN4i9/Nc9Rz/r32kSStA8Mri7oeqIsj7vj324QbXLrxghQN+TrwHABFIH/AzT59nAAAAAElFTkSuQmCC');
  left: -7px;
  bottom: 0;
}
.ribbon:after {
  content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAIcbAACL/wAA/RMAAIWJAACNJgAA5z0AADj/AAAeyY4zee8AAACMSURBVHja7MwxDoJAFIThf9fVAo2x4A5cwprKytPY2HoJS6/hCbwBoaKi0WxYoyYg+ywICQK9jdPNl/dG5Zu1AA44Azsg1TRZAlvgAkQttlkBhz4CxGO4GEP++Cs0yc2ilWI+NYRBwGyim0svwr2syApHWdff716E6/M13HxUbztAL3I0nW6BE7D/DADKLyXavmqdkAAAAABJRU5ErkJggg==');
  right: -5px;
  top: 0;
}

/** Content 9 **/
.content-9 h3 {
  margin: 0 0 73px;
  font-size: 35px;
  text-align: center;
  font-weight: normal
}
.content-9 .row {
  padding: 30px 0;
}

.content-9 .client-logo {
  margin-top: 65px;
}

.content-9 .client-close-quote,
.content-9 .client-open-quote {
  position: absolute;
  width: 30px;
}

.content-9 .client-open-quote {
  left: -20px;
}

.content-9 .client-close-quote {
  bottom: 10px;
  left: 80px;
}

.content-9 .client-quote {
  padding-left: 10px;
}
