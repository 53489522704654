.logo {
  margin: auto;
}

.logo .headline {
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  color: white !important;
  font-weight: normal !important;
  border-bottom: 3px solid white;
  font-size: 36px;
}

.logo .subline {
  text-align: center;
  margin-top: 4px;
  color: white !important;
  font-weight: normal !important;
  font-size: 30px;
  text-transform: uppercase;
}

.logo.mini .headline {
  font-size: 18px;
}

.logo.mini .subline {
  font-size: 16px;
}
